<div *ngIf="display">
    <header class="header theme-container" fxHide fxShow.gt-md>
        <div class="header-top">
            <div [class]="containerClass">
                <div class="header-left">
                    <div class="top-menu">
                        <div *ngIf="languageSelection==1">
                            <calsoft-language></calsoft-language>
                        </div>
                    </div>
                </div>

                <div class="header-right padding-10">

                    <ul class="top-menu">
                        <li>
                            <ul>
                                <li *ngIf="data.showContactNumber==1" (click)="callPhone()" style="cursor: pointer;"><a
                                        href="tel:#">Call: {{data.storePhoneNumber}}</a></li>
                                <li><a routerLink="/aboutus">About Us</a></li>
                                <li *ngIf="data.showContactPage==1"><a routerLink="/contact-us">Contact Us</a></li>
                                <li *ngIf="data.disableLoginHeader==0" class="login" style=" cursor: pointer;"><a
                                        *ngIf="!userLoggedIn" (click)="SignIn('login')"><i
                                            class="icon-user"></i>Login</a></li>

                                <calsoft-HeaderUserProfileDropdown [data]="data" fxLayoutAlign="end center"
                                    [color]="'white'" *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <mat-toolbar-row class="theme-container" fxLayoutAlign="start center" style="height: auto;">

            <div fxLayout="row" fxLayoutGap="10px" fxFlex="20">
                <section>

                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                </section>
            </div>

            <div fxLayout="row" fxLayoutGap="10px" fxFlex="60" style="height: -webkit-fill-available">

                <calsoft-Menu [color]="false" [data]="data" [type]="'one'" style="color: #000000;z-index: 1000;"
                    class="menu" [categoryArray]="item"></calsoft-Menu>

            </div>

            <div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayoutAlign="end center">

                <div *ngIf="data.disableSearchInHeader==0" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center"
                    fxFlex fxFlex.gt-sm="350px">
                    <calsoft-search [searchbarborderRadius]="'15px'"></calsoft-search>

                </div>
            </div>


            <div fxLayoutAlign="end center" fxFlex="10" *ngIf="url != '/cart'">
                <div *ngIf="data.disableWishlistHeader==0">
                    <button (click)="wishlist()" mat-button *ngIf="url != '/account/wishlist'">
                        <mat-icon>favorite_border</mat-icon>

                    </button>
                </div>
                <calsoft-cart-icon [color]="true" fxFlex></calsoft-cart-icon>

            </div>
        </mat-toolbar-row>
        <hr class="my-8">

        <mat-toolbar fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header primary" fxLayout="row"
            fxLayoutAlign="space-between center" style="  z-index: 1000;">
            <mat-toolbar-row class="theme-container" style="height: 100%;" fxLayoutAlign="space-between center">
                <section class="padding-10" style="padding-left: 7%;">
                    <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>

                </section>
                <section>
                    <div *ngIf="data.disableSearchInHeader==0" class="search-bar" fxHide fxShow.gt-sm fxShow.gt-sm fxLayout="row" fxLayoutAlign="start center"
                        fxFlex fxFlex.gt-sm="500px">
                        <calsoft-search [searchbarborderRadius]="'15px'"></calsoft-search>
                    </div>
                </section>
                <section fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">

                    <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
                        <div style="color: #ffffff" fxLayout="column">
                            <small>Hello, Sign in</small>
                            <span>Account & Lists</span>
                        </div>
                    </button>

                    <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser"
                        *ngIf="userLoggedIn">
                    </calsoft-HeaderUserProfileDropdown>

                    <calsoft-cart-icon [color]="false"></calsoft-cart-icon>
                </section>
            </mat-toolbar-row>
        </mat-toolbar>
    </header>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3  [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header3>
        </div>

        <div *ngIf="mobileHeaderType==3">
            <calsoft-mobile-header4  [data]="data" [color]="'white'" [url]="url" 
            [userLoggedIn]="userLoggedIn">
        
            </calsoft-mobile-header4>
        </div>
    
        <div *ngIf="mobileHeaderType==4">
            <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
            </calsoft-mobile-header5>
        </div>
        
</div>