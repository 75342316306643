import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constant } from 'src/app/constants/constant';
import { CommonService } from 'src/app/services/common.service';
import { SupplierRegistrationService } from 'src/app/services/supplier-registration.service';

@Component({
  selector: 'calsoft-supplier-dialog',
  templateUrl: './supplier-dialog.component.html',
  styleUrls: ['./supplier-dialog.component.scss']
})
export class SupplierDialogComponent {
  textAccount: string;
  text: string;


  constructor(private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private commonService: CommonService,
    private router: Router,
    private supplierRegistrationService : SupplierRegistrationService,
    private dialog: MatDialog,) { }

  ngOnInit() {
    this.textAccount = 'OK';
    this.typeYFilter()
    this.getAlldropdowntype();

    this.getCountryList();
    this.countrYFilter();
    this.getAllCountry();
  }

  firstFormGroup = this._formBuilder.group({
    gstNo: ['', [Validators.required]],
    phoneNo: ['', [Validators.required]],
    address: ['', [Validators.required]],
    pincode: ['', [Validators.required]],
    state: ['', [Validators.required]],
    city: ['', [Validators.required]],
    accountNo: ['', [Validators.required]],
    confirmAccount: ['', [Validators.required , this.matchAccountValidator]],
    ifscCode: ['', [Validators.required]], 
    storeName: ['', [Validators.required]],
    country: [''],
    name: ['',],
    isActive: [0],
    reason: [''],
    operationType: ['',],
    email:['', [Validators.required ,Validators.email] ],
    password:['' ,[Validators.required]],
    img:[''],

  });

  matchAccountValidator(control: FormGroup) {
    const  accountNo = control.get('accountNo');
    const  confirmAccount = control.get('confirmAccount');
  
    if (accountNo === confirmAccount) {
      return null; // Validation passes
    } else {
      return { mismatch: true }; // Validation fails
    }
  }
  
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  submit() {
    if (this.firstFormGroup.valid) {
    
      this.dialog.closeAll();
    }
    else {
      console.log("error");
      

    }
  }


  createSupplier() {
    if (this.firstFormGroup.valid) {
      this.supplierRegistrationService.submitSupplier(this.firstFormGroup.value).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.dialog.closeAll();
            this.router.navigate(['/supplierRegistration'])
          }
          else {
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { });
    }
    else {
      this.validateAllFormFields(this.firstFormGroup);
    }
  }

  //country

  countryList: any;
  countryTypeList: any=[];
  filteredcountryTypeList: any=[];
  countryControl: UntypedFormControl = new UntypedFormControl();
  countryFilterControl: UntypedFormControl = new UntypedFormControl();
  options: any;
  protected _onDestroy = new Subject<void>();
  filtereddropdownTypeList: any;


  getCountryList() {
    this.commonService.getCountryList().subscribe(
      res => {
        this.countryList = res['content'];
        this.countryTypeList = res['content'];
        this.filteredcountryTypeList = this.countryTypeList;
      },
      error => {
      })
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  getAllCountry() {
    this.filteredcountryTypeList = this.countryTypeList;
  }

  countrYFilter() {
    this.countryFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.countryFilter();
      });
  }

  protected countryFilter() {
    if (!this.countryTypeList) {
      return;
    }
    // get the search keyword
    let search = this.countryFilterControl.value;
    if (!search) {
      this.filteredcountryTypeList = this.countryTypeList.slice();
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredcountryTypeList = this.countryTypeList.filter(country => country['defaultName'].toLowerCase().indexOf(search) > -1)
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

   /// end of country 

     /** control for the MatSelect filter keyword multi-selection */
     public typeYFilterControl: UntypedFormControl = new UntypedFormControl();
     public regionId: UntypedFormControl = new UntypedFormControl();
    
     dropdownTypeList: any;
     dropdownList:any;
   
   
     typeYFilter() {
       this.typeYFilterControl.valueChanges
         .pipe(takeUntil(this._onDestroy))
         .subscribe(() => {
           this.typeidYFilter();
         });
     }
     
   
     protected typeidYFilter() {
       if (!this.dropdownTypeList) {
         return;
       }
       // get the search keyword
       let search = this.typeYFilterControl.value;
       if (!search) {
         this.filtereddropdownTypeList = this.dropdownTypeList.slice();
         return;
       } else {
         search = search.toLowerCase();
       }
       // filter the banks
       this.filtereddropdownTypeList = this.dropdownTypeList.filter(acccess => acccess['name'].toLowerCase().indexOf(search) > -1)
     }
   
     getAlldropdowntype() {
       this.filtereddropdownTypeList = this.dropdownTypeList;
     }

}
