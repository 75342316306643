import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldConfig } from '../field.interface';
@Component({
  selector: 'calsoft-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit{
  field: FieldConfig;
  group: UntypedFormGroup;
  constructor() {}
  optionsList: string[];
  ngOnInit() {

    let value:any= this.field.options;
    this.optionsList = value.replace(/\s/g, '').split(',');
    
    
  }
}
