import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'calsoft-logo-partner',
  templateUrl: './logo-partner.component.html',
  styleUrls: ['./logo-partner.component.scss']
})
export class LogoPartnerComponent implements OnInit {
  bigImage: string;

  @Input() tenantId: any;

  @Input() src: any;

  @Input() data: any;

  ngOnInit(): void {

    this.bigImage= environment.ImagePartnerAPI+"/"+this.tenantId+"/"+this.src;
   
  }

}
