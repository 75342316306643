<mat-toolbar class="mobileheader">
  <mat-toolbar-row style="padding: 0px;">
    <div class="padding-10" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div fxLayout="row" fxLayoutAlign="start center">
        <button (click)="searchLocation()" [style.color]="data.headerIconColour" class="responsive-toggle" mat-icon-button>
          <i class="material-icons">pin_drop</i>            
        </button><b [style.color]="data.headerIconColour">Other</b>
         
      </div>
      <button (click)="SignIn('login')" *ngIf="!userLoggedIn"  [style.color]="data.headerIconColour" class="responsive-toggle" mat-mini-fab>
        <mat-icon>person_outline</mat-icon>
      </button>
      
    </div>

       <button (click)="logindetails()" *ngIf="userLoggedIn"  [style.color]="data.headerIconColour"class="responsive-toggle" mat-mini-fab>
        <mat-icon>person</mat-icon>
      </button>

    <div fxFlex="3">

    </div>
    
  </mat-toolbar-row>
  <mat-toolbar-row style="height: 20px;font-size: 14px;">
  <p [style.color]="data.headerIconColour" class="truncate-text">{{location_address}}</p>
</mat-toolbar-row>
</mat-toolbar>
 