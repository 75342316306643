<span *ngIf="display" style="width: 100%; background: rgba(255, 255, 255, .5); backdrop-filter: blur(10px);" class="bg toolbar-container ">
    <span class="toolbar-content toolbar-container" fxLayout="row" fxLayoutAlign="space-between center">

        <span class=" toolbar-containerlogo-container" fxFlex="15">
            <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
        </span>

        <span class=" toolbar-containermenu-container" fxFlex="70" fxLayoutAlign="start center">
            <calsoft-Menu class="padding-10" [data]="data" [type]="'one'" [categoryArray]="item"></calsoft-Menu>
        </span>

        <div *ngIf="data.disableLoginHeader==0">
            <div *ngIf="!userLoggedIn" (click)="SignIn('login')" >
                <div *ngIf="data.disableLoginHeader==0">
                <button mat-button *ngIf="!userLoggedIn">
                    <mat-icon style="color: black;">person</mat-icon>
                </button>
                </div>
            </div>
        </div>

        <button mat-button fxLayoutAlign="end center">
            <mat-icon style="color: black;">phone_in_talk</mat-icon>
        </button>

        <span  class="toolbar-container" fxFlex="10"></span>

    </span>
</span>


<div *ngIf="!display">
 
    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
        
        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3  [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header3>
        </div>

        <div *ngIf="mobileHeaderType==3">
            <calsoft-mobile-header4  [data]="data" [color]="'white'" [url]="url" 
            [userLoggedIn]="userLoggedIn">
        
            </calsoft-mobile-header4>
        </div>
    
        <div *ngIf="mobileHeaderType==4">
            <calsoft-mobile-header5 [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">
            </calsoft-mobile-header5>
        </div>

</div> 
<mat-toolbar style="z-index: 1000;width: 100%;background: rgba(255, 255, 255, .5);
backdrop-filter: blur(10px);" fxHide fxShow.gt-md fxShow.md  class="calsoft-fixed-header bg toolbar-container" 
fxLayout="row"
  >
    <mat-toolbar-row class="theme-container" style="height: 100%;"  fxLayoutAlign="space-between center">
        <span fxFlex="100" class="toolbar-content toolbar-container" fxLayout="row" fxLayoutAlign="space-between center">
    
            <span class=" toolbar-containerlogo-container" fxFlex="15">
                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
            </span>
    
            <span class=" toolbar-containermenu-container" fxFlex="70" fxLayoutAlign="start center">
                <calsoft-Menu class="padding-10" [data]="data" [type]="'one'" [categoryArray]="item"></calsoft-Menu>
            </span>

            <div *ngIf="data.disableLoginHeader==0">
                <div *ngIf="!userLoggedIn" (click)="SignIn('login')" >
                    <div *ngIf="data.disableLoginHeader==0">
                    <button mat-button *ngIf="!userLoggedIn">
                        <mat-icon style="color: black;">person</mat-icon>
                    </button>
                    </div>
                </div>
            </div>

            <button mat-button fxLayoutAlign="end center">
                <mat-icon style="color: black;">phone_in_talk</mat-icon>
            </button>
    
            <span  class="toolbar-container" fxFlex="10"></span>
    
        </span>
    </mat-toolbar-row>
</mat-toolbar>