import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
@Injectable({
	providedIn: 'root'
})
export class CategoryService {

	constructor(private http: HttpClient) { }
	public responseCache = new Map();

	public responseCacheNew = new Map();

	public responseCacheSub = new Map();


	url = environment.serverAPI + '/api/catalog/categories';

	ur2 = environment.serverAPI + '/api/catalog/seocatlog';

	categoryById(id: any, type: any): Observable<Object> {
		return this.http.get(`${this.url}/breadCrump/${type}/new?categoryName=${id}`);
	}

	fetchByParentCategoryId(id: any): Observable<Object> {
		return this.http.get(`${this.url}/child?categoryId=${id}`);
	}
	getSeoPage(): Observable<Object> {
		return this.http.get(`${this.ur2}/dropdown`);
	}

	public categoryList(): Observable<any> {

		const categoryFromCache = this.responseCache.get(`${this.url}/idx/nestedPostion`);

		if (categoryFromCache) {
			return of(categoryFromCache); // Return cached data as an Observable
		} else {
			return this.http.get<any>(`${this.url}/idx/nestedPostion`).pipe(
				tap(category => this.responseCache.set(`${this.url}/idx/nestedPostion`, category))
			);
		}

	}

	public categoryListNotInMenu(): Observable<any> {

		const categoryFromCacheInActive = this.responseCacheNew.get(`${this.url}/idx/nested/menu/active`);

		if (categoryFromCacheInActive) {
			return of(categoryFromCacheInActive); // Return cached data as an Observable
		} else {
			return this.http.get<any>(`${this.url}/idx/nested/menu/active`).pipe(
				tap(category => this.responseCacheNew.set(`${this.url}/idx/nested/menu/active`, category))
			);
		}

	}

	
	public categoryListSubMenu(): Observable<any> {

		const categoryFromCacheInSub = this.responseCacheSub.get(`${this.url}/idx/nested/menu/sub`);

		if (categoryFromCacheInSub) {
			return of(categoryFromCacheInSub); // Return cached data as an Observable
		} else {
			return this.http.get<any>(`${this.url}/idx/nested/menu/sub`).pipe(
				tap(category => this.responseCacheSub.set(`${this.url}/idx/nested/menu/sub`, category))
			);
		}

	}


}
