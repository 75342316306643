import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { Constant } from '../constants/constant';
import { AnalyticService } from './analytic-service';
import { PixelService } from './pixel.service';
import { ShareChatTrackingService } from './share-chat-tracking.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private http: HttpClient,
    private userService: UserService,
    public googleAnalyticsService: AnalyticService,
    private pixelService:PixelService,
    private shareChatTrackingService:ShareChatTrackingService
    ) { }

  url = environment.serverAPI6 + '/' + 'api/partner/order';
  url2 = environment.serverAPI6 + '/' + 'api/ig/partner/order';
  url1 = environment.serverAPI6 + '/' + 'api/salesrule/apply';
  url3 = environment.serverAPI4 + '/' + 'api/ig/salesrule/apply';

  placeOrder(checkOut,conversionId,skuList,shareChatId): Observable<Response[]> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    this.setConversionTrackingId(conversionId,checkOut['grandTotal'],
    checkOut['extOrderId'])
    this.setFacebookConverison(skuList,checkOut['grandTotal']);

  
      this.setShareChatId(skuList,checkOut['grandTotal'],shareChatId);
    
    if(guestLoginEnabled=="0"||authourized){
    return this.http.post<Response[]>(`${this.url}`, checkOut);
    }else{
      checkOut['cartId']=localStorage.getItem(Constant['CART_ID']);
      return this.http.post<Response[]>(`${this.url2}`, checkOut);
    }
  }

  
  setConversionTrackingId(conversionId,grandTotal,extOrderId){
    if(conversionId!=null){
      let value:any= localStorage.getItem('currency');
      this.googleAnalyticsService.event('purchase',grandTotal);

      this.googleAnalyticsService.eventEmitter 
      ('conversion',conversionId,grandTotal,
      value,extOrderId);
    }
  }

  setShareChatId(skuList,grandTotal,shareChatId){
 

  if(shareChatId!=null){
    let value:any= localStorage.getItem('currency');
    const eventName = 'Purchase';
    const eventType = 'pre_defined';
    const customProps = {
      content_ids: skuList,  // Item SKUs
      value: grandTotal,                         // Value of all items
      currency: value                    // Currency of the value
    }
    this.shareChatTrackingService.sendCustomEvent(eventName, eventType, customProps);
  }
  }

  setFacebookConverison(skuList,grandTotal){
    let value:any= localStorage.getItem('currency');
    this.pixelService.track('Purchase', {
      content_ids: skuList,  // Item SKUs
      value: grandTotal,                         // Value of all items
      currency: value                    // Currency of the value
    });
  }


  applyCoupon(coupon): Observable<Response[]> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    if(guestLoginEnabled=="0"||authourized){
    return this.http.post<Response[]>(`${this.url1}`, coupon);
    }else{
      return this.http.post<Response[]>(`${this.url3}`, coupon);
    }
  }

  applyReferalDiscount(checkOut): Observable<Response[]> {
    let authourized = this.userService.getAuthenticatedUser();
    let guestLoginEnabled=localStorage.getItem(Constant['GUEST_LOGIN'])
    
    if(guestLoginEnabled=="0"||authourized){
    return this.http.post<Response[]>(`${this.url}/customer/commission`, checkOut);
    }else{
      checkOut['cartId']=localStorage.getItem(Constant['CART_ID']);
      return this.http.post<Response[]>(`${this.url2}/customer/commission`, checkOut);
    }
  }

  
}
