import { Input } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Constant } from 'src/app/constants/constant';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-button-dialog',
  templateUrl: './button-dialog.component.html',
  styleUrls: ['./button-dialog.component.scss']
})
export class ButtonDialogComponent {
  data: any;

  text: string;

  regConfig: any[] = [];

  @Input() input: any;
  @Input() item: any;

  constructor(private dialogRef: MatDialogRef<ButtonDialogComponent>,
    public snackBar: MatSnackBar,
    private contactService: ContactService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
  ) {

    this.data = data;

  }

  init: boolean = false;

  ngOnInit() {

    this.createDynamicForm(this.data.value['metaDescription']);

    this.text = 'Submit';

  }


  createDynamicForm(formGroupId) {
    this.contactService.getDynmaicForm(formGroupId).subscribe((data) => {

      let value: any = data
      this.regConfig = value;
      this.init = true;
    });
  }


  close() {
    this.dialogRef.close({ event: 'close' })
  }


  submit(value: any) {

    let filteredList = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('button'))
      .map(item => ({ label: item.label }));




    filteredList.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        delete value[label.label];
      }
    });

    let filteredListFile = this.regConfig
      .filter(item => item.inputType.toLowerCase().includes('file'))
      .map(item => ({ label: item.name }));

    filteredListFile.forEach(label => {
      if (value.hasOwnProperty(label.label)) {
        const newKey = label.label + '_file';
        value[newKey] = value[label.label];
        delete value[label.label];
      }
    });

    let object = {};
    let keysToCheck = ["phoneNumber", "email", "name"];
    let keysList = keysToCheck.filter(key => value.hasOwnProperty(key));
    keysList.forEach(key => {
      object[key] = value[key];
    });

    object['formGroupId'] = this.data.value['metaDescription'];
    object['value'] = JSON.stringify(value);



    this.saveForm(object)

  }


  saveForm(object) {
    if (this.text == 'Submit') {
      this.contactService.saveForm(object).subscribe(
        (response) => {
          if (response['status_code'] == Constant.RESPONSE_SUCCESS) {
            this.text = "Submit";
            this.snackBar.open(response['status_message'], '×', { panelClass: 'success', verticalPosition: 'bottom', duration: 3000 });
            this.close();
            this.router.navigate([''])
          }
          else {
            this.text = "Submit";
            this.snackBar.open(response['status_message'], '×', { panelClass: 'error', verticalPosition: 'bottom', duration: 3000 });
          }
        },
        (err) => { this.text = "Submit" });
    }
    else {

    }
  }



}
